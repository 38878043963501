

// main

// eslint-disable-next-line no-undef
var layzr = Layzr({
  normal: 'data-layzr',
  threshold: 50
});

layzr.on('src:after', function (elm) {
  if(!elm.hasAttribute('data-layzr-bg')) return;
  elm.style.backgroundImage = 'url(' + elm.getAttribute('src') + ')';
  elm.removeAttribute('src');
  elm.removeAttribute('data-layzr-bg');
});

document.addEventListener('DOMContentLoaded', function() {
    layzr.update().check().handlers(true)
}, false);

var userAgent = window.navigator.userAgent.toLowerCase();
var is_ie = (userAgent.indexOf('msie') != -1 || userAgent.indexOf('trident') != -1) ? true : false;

// eslint-disable-next-line no-undef
Swiper.prototype.getBreakpoint = function (breakpoints) {

  var window_size = window.innerWidth
  || document.documentElement.clientWidth
  || document.body.clientWidth;

  var swiper = this;
  // Get breakpoint for window width
  if (!breakpoints) {
      return undefined;
  }
  var breakpoint = false;
  var points = [];
  Object.keys(breakpoints).forEach(function (point) {
      points.push(point);
  });
  points.sort(function (a, b) {
      return parseInt(a, 10) - parseInt(b, 10);
  });
  for (var i = 0; i < points.length; i += 1) {
      var point = points[i];
      if (swiper.params.breakpointsInverse) {
          if (point <= window_size) {
              breakpoint = point;
          }
      } else if (point >= window_size && !breakpoint) {
          breakpoint = point;
      }
  }
  return breakpoint || 'max';
};

// accordion

$('.js-accordion-btn').on('click tap', function(e) {
  $(this).next('.js-accordion-target').slideToggle();
  $(this).parent().toggleClass('is-open');
});


// js menu open

var scrollpos;

$('.js-menu-toggle').on('click tap', function() {
  var status = $('body').attr('data-menu');
  if( status == 'active' ) {
    $('body').attr('data-menu', '');
    $('body').attr('data-bodyfixed', 'false').css({'top': 0});
    window.scrollTo( 0 , scrollpos );
  } else {
    scrollpos = $(window).scrollTop();
    $('body').attr('data-menu', 'active');
    $('body').attr('data-bodyfixed', 'true').css({'top': -scrollpos});
  }
});


// totop

$('.c-pagetop-btn').on('click tap', function() {
  $('body, html').animate({
    scrollTop: 0
  }, 300, 'swing');
});

/**
 * サイドナビ アンカー先の要素が表示領域内の時にアクティブにする
 */
/*
$(window).on('scroll', function() {
  $('.js-gnav-link').each(function() {
    let url = $(this).attr('href');
    var header = $('#js-header').outerHeight();

    if( url.indexOf('#') != -1 ) {
      let hash = url.match(/#.*$/);
      if($(hash[0])[0]) {
        if ( $(window).scrollTop() + header + 1 > $(hash[0]).offset().top && $(window).scrollTop() + header + 1 < $(hash[0]).offset().top + $(hash[0]).outerHeight() ) {
          $(this).addClass('is-active');
        }
        else {
          $(this).removeClass('is-active');
        }
      }
    }
  });
});
*/

// datepicker
//
// $(function() {
//   var headerHeight = $('header').height();
//   $('.datepicker').click(function(){
//     $('#ui-datepicker-div').css({
//       'margin-top': -headerHeight + 'px'
//     });
//   });
// });

$(window).on('load scroll', function() {
  var h = $(window).height();
  var w = $(window).width();
  var scrollTop = $(window).scrollTop();
  var btm;

  // header bg color

  if( $('.l-header')[0] ) {
    if (scrollTop > h / 2) {
      $('.l-header').addClass('is-active');
    }
    else {
      $('.l-header').removeClass('is-active');
    }
  }

  // pagetop

  if( $('.js-pagetop')[0] ) {
    if (scrollTop > h / 2) {
      $('.js-pagetop').addClass('is-active');

    }
    else {
      $('.js-pagetop').removeClass('is-active');

    }
  }
});



$(document).ready(function() {

  // scroll anchor

  var hHeight = $('#js-header').outerHeight();
  var urlHash = location.hash;
  var speed = 500;

  if(urlHash) {
    $('body,html').stop().scrollTop(0);
    setTimeout(function () {
      var target = $(urlHash);
      var position = target.offset().top - hHeight;
      // var position = target.offset().top;

      $('body,html').stop().animate({scrollTop:position}, speed, 'swing');
    }, 1000);
  }

  $('a[href]').click(function(e) {
    var anchor = $(this),
    href = anchor.attr('href'),
    pagename = window.location.href;

    if(href.match(/#.*/) && $('body').attr('data-menu') == 'active') {
      $('body').attr('data-menu', '');
      $('body').css({'overflow':'visible','height':'auto','top':'0'});

      // var acordion_status = $('.js-menu-acordion-toggle').parent().attr('data-menu');
      // if( acordion_status == 'active' ) {
      //   $('.js-menu-acordion-toggle').parent().attr('data-menu', '');
      // }
    }

    pagename = pagename.replace(/#.*/,'');

    href = href.replace( pagename , '' );

    if( href.search(/^#/) >= 0 ){
      e.preventDefault();

      // eslint-disable-next-line quotes
      var target = $(href == "#" || href == "" ? 'html' : href);
      var position;

      if(target.offset().top > hHeight) {
        position = target.offset().top - hHeight;
      } else {
        position = target.offset().top + 1;
      }

      $('html, body').animate({scrollTop:position}, speed, 'swing');

      location.hash = href;
      return false;
    }
  });

});

$(window).on('load', function() {

  loadContents();
});

function is_mobile() {
  if (
    (navigator.userAgent.indexOf('iPhone') > 0 && navigator.userAgent.indexOf('iPad') == -1) ||
    navigator.userAgent.indexOf('iPod') > 0 ||
    navigator.userAgent.indexOf('Android') > 0
  ) {
    return true;
  }
  else {
    return false;
  }
}

function getParam(name, url) {
  if (!url) url = window.location.href;
  name = name.replace(/[[]]/g, '\\$&');
  var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
      results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return '';
  return decodeURIComponent(results[2].replace(/\+/g, ' '));
}

function loadContents() {

  var content_show_timeout = ( location.hash && $(location.hash)[0] ) ? 300 : 500;
  var animate_start_timeout = ( location.hash && $(location.hash)[0] ) ? 300 : 200;

  setTimeout(function() {

    $('body').attr('data-status', 'loaded');

  }, content_show_timeout);

  setTimeout(function() {

    var timer = false;
    $(window).on('scroll', function () {

      eachAnimate();

      if( timer !== false ){
        clearTimeout( timer );
      }

      timer = setTimeout( function() {
        eachAnimate();
      }, 400 );

    });

    eachAnimate();
    setTimeout( function() {
      eachAnimate();
    }, 400 );

    var w = $(window).width();

    $('.js-kv-slide').each(function() {
      if($(this).find('.swiper-slide').length > 1) {
        // eslint-disable-next-line no-undef
        new Swiper($(this), {
          effect: 'fade',
          fadeEffect: {
            crossFade: true,
          },
          loop: true,
          slidesPerView: 1,
          speed: 2000,
          autoplay: {
            delay: 3000,
          }
        });
      }
    });

    if( $('.js-index-work-slide')[0] && $('.js-index-work-slide .swiper-slide').length > 1) {

      // eslint-disable-next-line no-undef
      var indexWorkSwiper = new Swiper('.js-index-work-slide.swiper-container', {
        effect: 'fade',
        fadeEffect: {
          crossFade: true,
        },
        loop: true,
        slidesPerView: 'auto',
        speed: 1500,
        autoplay: {
          delay: 3000,
          disableOnInteraction: false,
        },
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      });
    };

    if( $('.js-index-voice-slide')[0] && $('.js-index-voice-slide .swiper-slide').length > 1) {

      // eslint-disable-next-line no-undef
      var indexVoiceSwiper = new Swiper('.js-index-voice-slide.swiper-container', {
        effect: 'slide',
        fadeEffect: {
          crossFade: true,
        },
        autoHeight: true,
        loop: true,
        slidesPerView: 'auto',
        speed: 10000,
        centeredSlides: true,
        spaceBetween: 0,
        autoplay: {
          delay: 1,
          disableOnInteraction: false,
        }
      });
    };

    if( $('.js-p-menu-figure')[0] && $('.js-p-menu-figure .swiper-slide').length > 1) {

      // eslint-disable-next-line no-undef
      var menuPhotoSwiper = new Swiper('.js-p-menu-figure.swiper-container', {
        effect: 'fade',
        fadeEffect: {
          crossFade: true,
        },
        loop: true,
        speed: 1000,
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      });
    };

    afterLoadFile('https://fonts.googleapis.com/css2?family=EB+Garamond:wght@500&family=Shippori+Mincho&display=swap', 'css');
    // afterLoadFile('https://use.fontawesome.com/releases/v5.15.4/css/all.css', 'css');

  }, animate_start_timeout);


  //select data-url jump

  $('.js-select-jump').on('change', function() {
    var url = $(this).find('option:selected').data('url');
    if (url !== '') {
      setTimeout(function() { window.location = url; }, 400);
    }
  });

  //modal

  $('.js-modal-toggle').on('click tap', function() {
    let status = $('body').attr('data-modal');
    const scrollTop = $(window).scrollTop();
    let target;

    if($(this).data('modal')) {
      target = $('#' + $(this).data('modal'));
    } else {
      target = $(this).parents('.c-modal');
    }

    if( status == 'active' ) {
      // var window_scroll_num = $('body').css('top');
      // window_scroll_num = window_scroll_num.replace(/[^0-9]/g, '');

      // $('body').attr('data-modal', '').css({'top':0});

      $('body').attr('data-modal', '');
      // window.scrollTo(0,Number(window_scroll_num));

      target.removeClass('-open');
    } else {
      // $('body').attr('data-modal', 'active').css({'top':-Math.floor(scrollTop)});
      $('body').attr('data-modal', 'active');
      target.addClass('-open');
    }
  });

}

$('.js-work-modal').on('click', function() {
  var src = $(this).find('img').attr('src');

  if(src) {
    $('.js-work-modal-img').attr('src', src);
  }
  else {
    src = "";
    return false;
  }
});

function eachAnimate() {
  $('.js-animate').each(function () {
    var pos = $(this).offset().top;
    var h = $(window).height();
    var offset = ($(this).data('offset')) ? $(this).data('offset') : 200;
    var scrollTop = $(window).scrollTop() + offset;
    if (scrollTop > pos - h / 1.5) {
      $(this).addClass('is-active');
      $(this).removeClass('js-animate');
    }
  });
}

function afterLoadFile(src, type) {

  if( type == 'js' ) {
    $('body').append('<script src="'+src+'"></script>');
  }

  else if( type == 'css' ) {
    $('body').append('<link rel="stylesheet" href="'+src+'">');
  }

  else {
    return false;
  }

}

var video = $("#player01");

$(".js-sound-btn").click(function () {
  if($(this).hasClass("-off")) {
      $(this).toggleClass("-off");
      $(this).text('ON');
      $("video").prop('muted', false);
  } else {
      $(this).toggleClass("-off");
      $(this).text('OFF');
      $("video").prop('muted', true);
  }
});